import React, {useEffect } from 'react';
import {BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
// BsInstagram
import './Galerie.css';
import { images } from '../../constants';
import 'lightbox2/dist/css/lightbox.min.css';
import lightbox from 'lightbox2';

const GalerieFood2 = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 400;
    } else {
      current.scrollLeft += 400;
    }
  };
  useEffect(() => {
    // Add data-lightbox attribute to all images
    document.querySelectorAll('.app__gallery-images_card img').forEach(image => {
      image.setAttribute('data-lightbox', 'gallery');
    });
  
    // Override Lightbox options
    lightbox.option({
      'showImageNumberLabel': false,
      'alwaysShowNavOnTouchDevices': true,
      'maxWidth': 1200, // Set maximum width of the lightbox
      'maxHeight': 1000, // Set maximum height of the lightbox
      'scrollZoom': true, // Enable scrolling within the lightbox
      'wrapAround': true, // Allow wrapping around to the first/last image
      'positionFromTop': 100,
      'resizeDuration':20,
      'fadeDuration':20,
      'imageFadeDuration':20
    });
  
  }, []);
   
  

  return (
    <div className="app__gallery app__bg section__padding" id="GalerieFood2">
      <div className="app__gallery-images" >
        <div className="app__gallery-images_container" ref={scrollRef} >
        {[  
            images.papagalo_food11,
            images.papagalo_food12,
            images.papagalo_food13,
            images.papagalo_food14,
            images.papagalo_food15,
            images.papagalo_food16,
            images.papagalo_food17,
            images.papagalo_food18, 
            images.papagalo_food19, 
          ].map((image, index) => (
            <a href={image} data-lightbox="gallery-food2" key={`gallery-food2_image-${index + 1}`}>
              <div className="app__gallery-images_card flex__center" >
                <img src={image} alt="gallery_image" />
              </div>
            </a>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  );
};

export default GalerieFood2;
