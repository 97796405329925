import React, { useState, useRef } from "react";
import SubHeading from "../SubHeading/SubHeading";
import "./Newsletter.css";
import emailjs from '@emailjs/browser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de'; // Import the German locale
import { format } from 'date-fns';

const Newsletter = () => {
  const [date, setDate] = useState(new Date());
  const [toggleFromAnfrage, setToggleFormAnfrage] = useState(false);
  const [toggleFromReservierung, setToggleFormReservierung] = useState(false);
  const [values, setValues] = useState({
    name: "",
    persons: "",
    email: "",
    phone: "",
    message: ""
  });
  const form = useRef();
  const [errors, setErrors] = useState({}); // State variable for errors
  const [isSent, setIsSent] = useState(false);
  const [submissionCount, setSubmissionCount] = useState(0);
  const [MaxSubmissionCount, setMaxSubmissionCount] = useState(false);
  const [ErrorSending, setErrorSending] = useState(false);
  const handleChangeDate = (date) => {
    setDate(date);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Check if the input field is 'persons' and limit the number of digits to 2
    const truncatedValue = name === 'persons' ? value.slice(0, 2) : value;
    
    setValues((prevState) => ({
      ...prevState,
      [name]: truncatedValue
    }));
    
    // Reset errors when input value changes
    setErrors({});
  };
  
  // Function to validate inputs
  const checkInputs = () => {
    let errors = {};
    if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Die E-Mail ist ungültig";
      console.log(errors.email)
    }
    // Regular expression for German phone numbers (supports common formats)
    const germanPhoneNumberRegex = /^(?:\+49|0)[1-9][0-9]{1,14}$/;

    if (!germanPhoneNumberRegex.test(values.phone)) {
      errors.phone = "Telefonnummer ist ungültig";
      console.log(errors.phone)
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const sendEmailAnfrage = (e) => {
    e.preventDefault();
    setIsSent(false)
    setErrorSending(false)
    if(checkInputs() === true)
    {
      if (submissionCount >= 3) {
            setMaxSubmissionCount(true)
            return;
      }
      emailjs.sendForm('service_izh9mji', 'template_nxw34kj', form.current, '-W_SMAcBDMIT6mwDL')
        .then((result) => {
            setIsSent(true); // Update state to indicate email is sent
            setSubmissionCount(submissionCount + 1);
            e.target.reset();
        }, (error) => {
            console.log(error.text);
            setErrorSending(true)
        });
        form.current.reset();
        setValues({
          name: "",
          persons: "",
          email: "",
          phone: "",
          message: ""
        });
        setErrors({});
    }

  };
  const sendEmailReservierung = (e) => {
    e.preventDefault();
    setIsSent(false)
    setErrorSending(false)
    if(checkInputs() === true)
    {
      if (submissionCount >= 3) {
            setMaxSubmissionCount(true)
            return;
      }
      
      emailjs.sendForm('service_izh9mji', 'template_1kgvs02', form.current,'-W_SMAcBDMIT6mwDL')
        .then((result) => {
            setIsSent(true); // Update state to indicate email is sent
            e.target.reset();
        }, (error) => {
            console.log(error.text);
            setErrorSending(true)
        });
        form.current.reset();
        setValues({
          name: "",
          persons: "",
          email: "",
          phone: "",
          message: ""
        });
        setErrors({});
    }

  }
  const filterSundays = (date) => {
    // Disable Sundays
    return date.getDay() !== 0;
  };

  const filterCustomTimes = (time) => {
    // Check the day of the week (0-6, where 0 is Sunday)
    const dayOfWeek = date.getDay();

    // Custom time ranges
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      // Monday to Friday: 12:00 - 14:00 and 18:00 - 21:45
      return (time.getHours() >= 12 && time.getHours() <= 13 && time.getMinutes() <= 30) || (time.getHours() >= 18 && time.getHours() <= 21 && time.getMinutes() <= 45);
    } else if (dayOfWeek === 6) {
      // Saturday: 18:00 - 21:45
      return time.getHours() >= 18 && time.getHours() <= 21 && time.getMinutes() <= 45;
    }

    // Default: Allow all times for other days
    return true;
  };
  
  return (
    <div className="app__newsletter">
      <div className="app__newsletter-heading">
        <SubHeading title="Kontaktieren Sie uns" icon="spoon" />
        <h1 className="headtext__cormorant">Anfragen ~ Reservieren</h1>
        <p className="p__opensans">
        Sie erreichen uns direkt per Mail al.pappagallo@gmx.de oder telefonisch unter (0421) 32 79 63.
        </p>
        <p className="p__opensans">
          Alternativ können Sie unser Kontaktformular benutzen.
        </p>
        <p className="p__opensans">Wir freuen uns auf Sie!</p>
      </div>

      <div className="button_holder flex__center">
        <div className="toggle-buttons">
          <input
            type="radio"
            id="b1"
            name="group-b"
            onClick={() => {
              setToggleFormAnfrage(true);
              setToggleFormReservierung(false);
            }}
          />
          <label htmlFor="b1">Anfrage</label>
          <input
            type="radio"
            id="b2"
            name="group-b"
            onClick={() => {
              setToggleFormReservierung(true);
              setToggleFormAnfrage(false);
            }}
          />
          <label htmlFor="b2">Reservierung</label>
        </div>
      </div>

      {toggleFromAnfrage && (
        <form ref={form} onSubmit={sendEmailAnfrage} className="app__newsletter-input flex__center">
          {isSent && (
            <div className="input-container">
              <p className="success-message">Ihre Nachricht wurde erfolgreich gesendet.</p>
            </div>
          )}
          {MaxSubmissionCount && (
          <div className="input-container">
            <p className="error-message">Übertragungslimit überschritten. Bitte versuchen Sie es später noch einmal.</p>
          </div>
          )}
          {ErrorSending && (
          <div className="input-container">
            <p className="error-message">Die E-Mail konnte nicht gesendet werden. Bitte nehmen Sie direkt per E-Mail oder telefonisch Kontakt mit uns auf.</p>
          </div>
        )}
          <div className="input-container">
          <input
            value={values.name}
            onChange={handleChange}
            className="my_input"
            type="text"
            name="name"
            required="required"
            placeholder="Name*"
          />
          </div>
          <div className="input-container">
          <input
            value={values.email}
            onChange={handleChange}
            className="my_input"
            type="email"
            name="email"
            required="required"
            placeholder="Email*"
          />
          {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <div className="input-container">
          <input
            value={values.phone}
            onChange={handleChange}
            className="my_input"
            type="text"
            name="phone"
            required="required"
            placeholder="Telefonnummer*"
          />
          {errors.phone && <p className="error">{errors.phone}</p>}
          </div>
          <div className="input-container">
          <textarea
            value={values.message}
            onChange={handleChange}
            className="textarea"
            name="message"
            placeholder="Nachricht*"
          />
          </div>
          <input type="submit" className="submit_button" value="Senden" />
        </form>
      )}

      {toggleFromReservierung && (
        <form ref={form} onSubmit={sendEmailReservierung} className="app__newsletter-input flex__center">
        {isSent && (
          <div className="input-container">
            <p className="success-message ">Ihre Nachricht wurde erfolgreich gesendet.</p>
          </div>
        )}
        {MaxSubmissionCount && (
          <div className="input-container">
            <p className="error-message">Übertragungslimit überschritten. Bitte versuchen Sie es später noch einmal.</p>
          </div>
        )}
        {ErrorSending && (
          <div className="input-container">
            <p className="error-message">Die E-Mail konnte nicht gesendet werden. Bitte nehmen Sie direkt per E-Mail oder telefonisch Kontakt mit uns auf.</p>
          </div>
        )}
          {/* <DatePicker   
            value={date}
            onChange={handleChangeDate}
            locale="de"
            format="DD/MM/YYYY HH:mm"
            className="rmdp-mobile"
            name="date"
            required="required"
            placeholder="Datum und Zeitpunt*"
            weekStartDayIndex={1}
            excludeDates={[new Date(), subDays(new Date(), 1)]}
            plugins={[
              <TimePicker hideSeconds />
            ]} 
          /> */}
          <div className="input-container">
           <DatePicker
            name="date"
            value={format(date, "d. MMMM yyyy : HH:mm", { locale: de })}
            selected={date}
            onChange={handleChangeDate}
            showTimeSelect
            dateFormat="d, MMMM, yyyy, HH:mm"
            Format="d, MMMM, yyyy, HH:mm"
            minDate={new Date()}
            filterDate={filterSundays}
            filterTime={filterCustomTimes}
            excludeTimes={[]} // Set to an empty array to hide disabled times
            locale={de} // Set the locale to German
            placeholderText="Datum und Zeitpunt*"
            className="my_input"
            timeIntervals={15}
            onKeyDown={(e) => {
              e.preventDefault();
           }}
          />
          </div>
          <div className="input-container">
          <input
            value={values.name}
            onChange={handleChange}
            className="my_input"
            type="text"
            name="name"
            required="required"
            placeholder="Name*"
          />
          </div>
          <div className="input-container">
          <input
            value={values.persons}
            onChange={handleChange}
            onKeyDown={(event) => {
              const allowedKeys = ["Backspace", "Tab", "Enter", "Escape", "Delete"];
              if (
                !(
                  (event.key >= "0" && event.key <= "9") ||
                  allowedKeys.includes(event.key)
                )
              ) {
                event.preventDefault();
              }
            }}
            className="my_input"
            type="text"
            name="persons"
            required="required"
            placeholder="Zahl der Personen*"
          />
          </div>
          <div className="input-container">
          <input
            value={values.email}
            onChange={handleChange}
            className="my_input"
            type="email"
            name="email"
            required="required"
            placeholder="Email*"
          />
          {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <div className="input-container">
          <input
            value={values.phone}
            onChange={handleChange}
            className="my_input"
            type="text"
            name="phone"
            required="required"
            placeholder="Telefonnummer*"
          />
          {errors.phone && <p className="error">{errors.phone}</p>}
          </div>
          <div className="input-container">
          <textarea
            value={values.message}
            onChange={handleChange}
            className="textarea"
            name="message"
            placeholder="Nachricht*"
          />
          </div>
          <input type="submit" className="submit_button" value="Senden" />
        </form>
      )}
    </div>
  );
};

export default Newsletter;
