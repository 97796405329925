import bg from '../assets/bg.png';
import bgGrey from '../assets/bg-grey.jpg';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import logo_default from '../assets/logo_default.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import pappagalo from '../assets/pappagalo.jpg';
import location from '../assets/location.jpg';
import MenuKarte from '../assets/Menu-karte.png';
import headerImg from '../assets/new_home.jpg';
import catering from '../assets/new_home1.jpg';
import video1 from '../assets/meal.mp4';
import Michelin_front from '../assets/Michelin/front.png';
import Michelin_2010 from '../assets/Michelin/2010.jpg';
import Michelin_2011 from '../assets/Michelin/2011.jpg';
import Michelin_2012 from '../assets/Michelin/2012.jpg';
import Michelin_2013 from '../assets/Michelin/2013.jpg';
import Michelin_2014 from '../assets/Michelin/2014.jpg';
import Michelin_2015 from '../assets/Michelin/2015.jpg';
import Michelin_2016 from '../assets/Michelin/2016.jpg';
import Michelin_2017 from '../assets/Michelin/2017.jpg';
import Michelin_2018 from '../assets/Michelin/2018.jpg';
import Michelin_2019 from '../assets/Michelin/2019.jpg';
import Michelin_2020 from '../assets/Michelin/2020.jpg';
import Michelin_2021 from '../assets/Michelin/2021.jpg';
import Michelin_2022 from '../assets/Michelin/2022.jpg';
import Michelin_2023 from '../assets/Michelin/2023.jpg';
import papagalo_img_background from '../assets/papagalo-img/background.jpg';
import papagalo_img_front from '../assets/papagalo-img/front.jpg';
import papagalo_img1 from '../assets/papagalo-img/1.jpg';
import papagalo_img2 from '../assets/papagalo-img/2.jpg';
import papagalo_img3 from '../assets/papagalo-img/3.jpg';
import papagalo_img4 from '../assets/papagalo-img/4.jpg';
import papagalo_img5 from '../assets/papagalo-img/5.jpg';
import papagalo_img6 from '../assets/papagalo-img/6.jpg';
import papagalo_img7 from '../assets/papagalo-img/7.jpg';
import papagalo_img8 from '../assets/papagalo-img/8.jpg';
import papagalo_img9 from '../assets/papagalo-img/9.jpg';
import papagalo_img10 from '../assets/papagalo-img/10.jpg';
import papagalo_video from '../assets/papagalo-img/video.mp4';


import papagalo_food1 from '../assets/papagalo-food/1.jpg';
import papagalo_food2 from '../assets/papagalo-food/2.jpg';
import papagalo_food3 from '../assets/papagalo-food/3.jpg';
import papagalo_food4 from '../assets/papagalo-food/4.jpg';
import papagalo_food5 from '../assets/papagalo-food/5.jpg';
import papagalo_food6 from '../assets/papagalo-food/6.jpg';
import papagalo_food7 from '../assets/papagalo-food/7.jpg';
import papagalo_food8 from '../assets/papagalo-food/8.jpg';
import papagalo_food9 from '../assets/papagalo-food/9.jpg';
import papagalo_food10 from '../assets/papagalo-food/10.jpg';
import papagalo_food11 from '../assets/papagalo-food/11.jpg';
import papagalo_food12 from '../assets/papagalo-food/12.jpg';
import papagalo_food13 from '../assets/papagalo-food/13.jpg';
import papagalo_food14 from '../assets/papagalo-food/14.jpg';
import papagalo_food15 from '../assets/papagalo-food/15.jpg';
import papagalo_food16 from '../assets/papagalo-food/16.jpg';
import papagalo_food17 from '../assets/papagalo-food/17.jpg';
import papagalo_food18 from '../assets/papagalo-food/18.jpg';
import papagalo_food19 from '../assets/papagalo-food/19.jpg';

import papagalo_dessert1 from '../assets/papagalo-food/dessert/1.jpg';
import papagalo_dessert2 from '../assets/papagalo-food/dessert/2.jpg';
import papagalo_dessert3 from '../assets/papagalo-food/dessert/3.jpg';
import papagalo_dessert4 from '../assets/papagalo-food/dessert/4.jpg';
import papagalo_dessert5 from '../assets/papagalo-food/dessert/5.jpg';

import weinkarte1 from '../assets/papagalo-weinkarte/1.jpg';
import weinkarte2 from '../assets/papagalo-weinkarte/2.jpg';
import weinkarte3 from '../assets/papagalo-weinkarte/3.jpg';
import weinkarte4 from '../assets/papagalo-weinkarte/4.jpg';
import weinkarte5 from '../assets/papagalo-weinkarte/5.jpg';
import weinkarte6 from '../assets/papagalo-weinkarte/6.jpg';
import weinkarte7 from '../assets/papagalo-weinkarte/7.jpg';
import weinkarte8 from '../assets/papagalo-weinkarte/8.jpg';
import weinkarte9 from '../assets/papagalo-weinkarte/9.jpg';
import weinkarte10 from '../assets/papagalo-weinkarte/10.jpg';

import speisekarte1 from '../assets/papagalo-speisekarte/1.jpg';
import speisekarte2 from '../assets/papagalo-speisekarte/2.jpg';
import speisekarte3 from '../assets/papagalo-speisekarte/3.jpg';
import speisekarte4 from '../assets/papagalo-speisekarte/4.jpg';
import speisekarte5 from '../assets/papagalo-speisekarte/5.jpg';
import speisekarte6 from '../assets/papagalo-speisekarte/6.jpg';



export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  logo_default,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  pappagalo,
  location,
  MenuKarte,
  headerImg,
  papagalo_img_background,
  papagalo_img_front,
  papagalo_img1,
  papagalo_img2,
  papagalo_img3,
  papagalo_img4,
  papagalo_img5,
  papagalo_img6,
  papagalo_img7,
  papagalo_img8,
  papagalo_img9,
  papagalo_img10,
  papagalo_video,


  papagalo_food1,
  papagalo_food2,
  papagalo_food3,
  papagalo_food4,
  papagalo_food5,
  papagalo_food6,
  papagalo_food7,
  papagalo_food8,
  papagalo_food9,
  papagalo_food10,
  papagalo_food11,
  papagalo_food12,
  papagalo_food13,
  papagalo_food14,
  papagalo_food15,
  papagalo_food16,
  papagalo_food17,
  papagalo_food18,
  papagalo_food19,

  papagalo_dessert1,
  papagalo_dessert2,
  papagalo_dessert3,
  papagalo_dessert4,
  papagalo_dessert5,
  
  video1,

  Michelin_front,
  Michelin_2010,
  Michelin_2011,
  Michelin_2012,
  Michelin_2013,
  Michelin_2014,
  Michelin_2015,
  Michelin_2016,
  Michelin_2017,
  Michelin_2018,
  Michelin_2019,
  Michelin_2020,
  Michelin_2021,
  Michelin_2022,
  Michelin_2023,
  
  weinkarte1,
  weinkarte2,
  weinkarte3,
  weinkarte4,
  weinkarte5,
  weinkarte6,
  weinkarte7,
  weinkarte8,
  weinkarte9,
  weinkarte10,
  
  speisekarte1,
  speisekarte2,
  speisekarte3,
  speisekarte4,
  speisekarte5,
  speisekarte6,
  catering


};
